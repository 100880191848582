import React from 'react'
import { Helmet } from 'react-helmet'
import Download from '../components/Download/Download'
import * as letoltesStyles from '../css/letoltes.module.css'

const Letoltes = () => {
    return (
        <>
            <Helmet bodyAttributes={{
                class: letoltesStyles.body
            }}>
                <meta charSet="iso-8859-1" />
                <title>Letöltés - Aznap Projekt - Live in Szimpla</title>
            </Helmet>
            <section>
                <div className={letoltesStyles.letoltes}>
                    <Download />
                </div>
            </section>
        </>
    )
}

export default Letoltes