import React, { useState } from 'react'
import Message from '../Messages/Messages'
import LinkedFileList from '../LinkedFileList/LinkedFileList'
import useAlbumFileList from '../../hooks/useAlbumFileList/useAlbumFileList'
import codes from '../../utils/codes'
import * as downloadStyles from '../../css/download.module.css'

const initialState = {
    value: '',
    loading: false,
    allowed: false,
    failed: false,
    empty: false,
    fileList: []
}

const Download = () => {
    const [
        { value, loading, allowed, failed, empty, fileList },
        setState
    ] = useState(initialState)
    
    const albumFieldList = useAlbumFileList()

    const handleChange = e => {
        resetState()
        const { value } = e.target
        setState(prevState => ({ ...prevState, value }))
    }

    const handleSubmit = e => {
        setState(prevState => ({ ...prevState, loading: true }))

        if (value.length) {
            if (codes.includes(value)) {
                setState(prevState => ({ ...prevState, allowed: true, fileList: albumFieldList }))
            } else {
                setState(prevState => ({ ...prevState, failed: true }))
            }
        } else {
            setState(prevState => ({ ...prevState, empty: true }))
        }
        
        setState(prevState => ({ ...prevState, loading: false }))
        e.preventDefault()
    }

    const handleBack = e => {
        resetState()
        e.preventDefault()
    }

    const resetState = () => {
        setState({ ...initialState })
    }
    
    const fileSongMap = {
        '1_hiperdur.mp3': 'Hiperdúr',
        '2_szamtalan.mp3': 'Számtalan',
        '3_borzsonyi_betyar.mp3': 'Börzsönyi Betyár',
        '4_tekeros.mp3': 'Tekerős',
        '5_cierna_wisnia.mp3': 'čierna wiśnia'
    }

    let message = null

    let handle = (
        <>
            <input type="text" value={value} onChange={handleChange} /> <input type="submit" value="&#9658;" />
        </>
    )
    if (loading)
        message = <span className={downloadStyles.loading}>Betöltés...<br />Loading...</span>
    if (allowed) {
        message = <input type="submit" value="&#9650;" onClick={handleBack} />
        handle = <LinkedFileList
            path="zene/album/live-in-szimpla"
            fileList={fileList}
            fileSongMap={fileSongMap} />
    }
    if (failed)
        message = <Message className={downloadStyles.error}>Hibás kód!<br />Invalid code!</Message>
    if (empty)
        message = <Message className={downloadStyles.error}>Üres a mező<br />Field is empty</Message>

    return (
        <form onSubmit={handleSubmit}>
            <p>
                {handle}
            </p>
            <p>
                {message}
            </p>
        </form>
    )
}

export default Download