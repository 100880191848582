import React, { Fragment } from 'react'

const LinkedFileList = ({ path, fileList, songMap }) => {
    const fileSongMap = songMap ?? fileList.reduce((coll, fn) => {
        coll[fn] = fn
        return coll
    }, {})
    
    let linkedFileList

    if (fileList.length) {
        linkedFileList = fileList.map((fileName, i) => {
            const link = `${path}/${fileName}`
            
            return (
                <Fragment key={i}>
                    <span>
                        <a href={link} download>{fileSongMap[fileName]} &#10515;</a>
                    </span>
                    <br />
                </Fragment>
            )
        })
    } else {
        linkedFileList =
            <span
                style={{ color: 'white' }}>[ itt <i>kellene</i> lennie... ]<br />[ it <i>should</i> be here... ]</span>
    }

    return linkedFileList
}

export default LinkedFileList