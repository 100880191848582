/**
 * @link https://stackoverflow.com/a/24172140/2037924
 */

import React, { useState, useEffect, useRef } from 'react'

const messageHideDelay = 3000;

const Message = ({ children, className, delay }) => {
    const [showMessage, setShowMessage] = useState(true)
    const timer = useRef(null)
    
    useEffect(() => {
        const setTimer = () => {
            if (timer.current !== null) {
                clearTimeout(timer.current)
            }
            
            let innerDelay = delay ?? messageHideDelay;

            timer.current = setTimeout(function(){
                setShowMessage(false)
                timer.current = null
            }, innerDelay);
        }
        
        setTimer();
    }, [delay])

    return showMessage ? <span className={className}>{children}</span> : null;
}

export default Message