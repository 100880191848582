import { useStaticQuery, graphql } from "gatsby"

const useAlbumFileList = () => {
    const data = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "album/live-in-szimpla"}}) {
                nodes {
                    relativePath
                }
            }
        }
    `)
    
    /** @namespace data.allFile */
    return data.allFile.nodes.map(node => node.relativePath.substring("album/live-in-szimpla/".length))
}

export default useAlbumFileList